import { render, staticRenderFns } from "./FarmPeriodSwitcher.vue?vue&type=template&id=7ff2b0c0&scoped=true&"
import script from "./FarmPeriodSwitcher.vue?vue&type=script&lang=ts&"
export * from "./FarmPeriodSwitcher.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ff2b0c0",
  null
  
)

export default component.exports