import { FarmItem } from '@/models/FarmItem'

export class FarmItemConverter implements firebase.firestore.FirestoreDataConverter<FarmItem> {
  toFirestore(item: FarmItem): firebase.firestore.DocumentData {
    return {
      name: item.name,
      description: item.description,
      amount: item.amount,
      type: item.type,
      available_weekdays: item.availableWeekdays,
      image_url: item.imageUrl,
      active: item.active
    }
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>,
    options: firebase.firestore.SnapshotOptions) {
    const data = snapshot.data(options)
    const item = new FarmItem(snapshot.ref)
    item.name = data.name
    item.description = data.description
    item.amount = data.amount
    item.type = data.type
    item.imageUrl = data.image_url
    item.availableWeekdays = data.available_weekdays ?? []
    item.active = data.active
    return item
  }
}