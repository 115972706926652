














import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class Tile extends Vue {
  @Prop()
  title: string
}
