

















































































import { Component, Vue } from 'vue-property-decorator'
import Tile from '@/components/Tile.vue'
import Table from '@/components/Table.vue'
import FarmPeriodSwitcher from '@/components/FarmPeriodSwitcher.vue'
import Button from '@/components/Button.vue'
import FarmSwitcher from '@/components/FarmSwitcher.vue'
import { mixins } from 'vue-class-component'
import FarmMixin from '@/mixins/FarmMixin.vue'
import { Farm } from '@/models/Farm'
import { FarmPeriod } from '@/models/FarmPeriod'
import { FarmMessage } from '@/models/FarmMessage'
import { FarmMessageConverter } from '@/models/converters/FarmMessageConverter'
import ControlMixin from '@/mixins/ControlMixin.vue'
import firebase, { firestore } from 'firebase/app'
import { FarmConverter } from '@/models/converters/FarmConverter'
import { showNotification } from '@/services/NotificationService'
import UserMixin from '@/mixins/UserMixin.vue'
import { TableHeader } from '@/models/TableHeader'
import { FarmItem } from '@/models/FarmItem'
import { FarmItemConverter } from '@/models/converters/FarmItemConverter'

@Component({
  components: {
    Tile,
    Table,
    FarmPeriodSwitcher,
    Button,
    FarmSwitcher
  }
})
export default class AdminFarmItems extends mixins(
  UserMixin,
  FarmMixin,
  ControlMixin
) {
  farm: Farm = null
  headers: TableHeader[] = [
    { key: 'name', title: 'Name' },
    { key: 'amount', title: 'Preis', classes: ['hidden', 'lg:table-cell'] },
    { key: 'action', title: '' }
  ]
  query: firestore.Query<FarmItem> = null

  async created() {
    if (this.currentFarm) {
      this.farm = this.currentFarm

      await this.loadItems()
    }
  }

  async loadItems() {
    this.query = firestore()
      .collection('farms')
      .doc(this.farm.id)
      .collection('items')
      .withConverter(new FarmItemConverter())
      .orderBy('name', 'asc')
  }

  async farmChanged() {
    await this.loadItems()
  }
}
