








import {
  Component,
  Emit,
  Model,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator'
import { Farm } from '@/models/Farm'
import firebase from 'firebase/app'
import { FarmPeriod } from '@/models/FarmPeriod'
import { FarmPeriodConverter } from '@/models/converters/FarmPeriodConverter'
import moment from 'moment'

@Component({
  components: {}
})
export default class FarmPeriodSwitcher extends Vue {
  @Prop()
  farm: Farm

  @Prop()
  @Model('change')
  value: FarmPeriod

  periods: FarmPeriod[] = []

  async created() {
    await this.loadPeriods()
  }

  get selectedPeriodId() {
    if (this.value) {
      const period = this.periods.find((p) => p.id === this.value.id)
      return period ? period.id : this.periods[0].id
    }
    return this.periods[0].id
  }

  set selectedPeriodId(value: string) {
    this.updateValue(this.periods.find((p) => p.id === value))
  }

  get currentPeriod(): FarmPeriod {
    return (
      (this.periods.filter((p) => moment(p.slaughterDay).isAfter(moment())) ||
        [])[0] ?? this.periods[0]
    )
  }

  async loadPeriods() {
    this.periods = (
      await firebase
        .firestore()
        .collection('farms')
        .doc(this.farm.id)
        .collection('periods')
        .withConverter(new FarmPeriodConverter())
        .orderBy('slaughter_day', 'asc')
        .get()
    ).docs.map((doc) => doc.data())

    if (!this.value) {
      this.updateValue(this.currentPeriod)
    }
  }

  @Watch('farm')
  async onFarmChanged() {
    await this.loadPeriods()
    this.updateValue(this.currentPeriod)
  }

  @Emit('change')
  updateValue(value: FarmPeriod) {
    return value
  }
}
