
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mainStore } from '@/store'

@Component
export default class FarmMixin extends Vue {
  get currentFarm() {
    return mainStore.currentFarm
  }
}
