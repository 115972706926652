





























































import Button from '@/components/Button.vue'
import Loading from '@/components/Loading.vue'
import { Model } from '@/models/Model'
import { TableHeader } from '@/models/TableHeader'
import { firestore } from 'firebase/app'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: {
    Button,
    Loading
  }
})
export default class Table extends Vue {
  loading: boolean = true
  refetching: boolean = false

  @Prop()
  headers: TableHeader[]

  @Prop()
  query: firestore.Query<Model>

  @Prop({ default: 10 })
  pageSize: number

  @Prop()
  populate: (items: Model[]) => Promise<Model[]>

  @Prop({ default: true })
  paged: boolean

  @Prop({ default: false })
  realtime: boolean

  @Prop({ default: false })
  dense: boolean

  @Prop()
  staticItems: Model[]

  pageNumber: number = 1
  lastPageNumber: number | null = null

  initial: firestore.QueryDocumentSnapshot<Model> = null
  first: firestore.QueryDocumentSnapshot<Model> = null
  last: firestore.QueryDocumentSnapshot<Model> = null
  items: Model[] = []
  unsubscriber: () => void

  @Watch('query')
  async queryChanged(query: firestore.Query<Model>) {
    await this.applyQuery()
  }

  get hasPreviousPage() {
    return this.first && this.initial && this.initial.id !== this.first.id
  }

  get hasNextPage() {
    return !!this.last && !this.onLastPage
  }

  get onLastPage() {
    return !!this.lastPageNumber && this.lastPageNumber === this.pageNumber
  }

  async created() {
    if (this.staticItems) {
      this.loading = false
      this.items = this.staticItems
    } else {
      this.loading = true
      await this.applyQuery()
    }
  }

  async applyQuery() {
    if (this.realtime) {
      this.unsubscriber = this.query.onSnapshot(async (snapshot) => {
        const items = snapshot.docs.map((doc) => doc.data())
        if (this.populate) {
          this.items = await this.populate(items)
        } else {
          this.items = items
        }
        this.loading = false
      })
    } else {
      if (this.paged) {
        await this.load(this.query.limit(this.pageSize))
      } else {
        await this.load(this.query)
      }
      this.loading = false
    }
  }

  async previousPage() {
    if (
      await this.load(
        this.query.endBefore(this.first).limitToLast(this.pageSize)
      )
    ) {
      this.pageNumber--
    }
  }

  async nextPage() {
    if (
      await this.load(this.query.startAfter(this.last).limit(this.pageSize))
    ) {
      this.pageNumber++
    }
  }

  async load(query: firestore.Query<Model>) {
    this.refetching = true

    const docs = (await query.get()).docs
    if (docs.length === 0) {
      this.last = null
      this.refetching = false
      this.lastPageNumber = this.pageNumber
      return false
    }

    this.first = docs[0]
    if (!this.initial) {
      this.initial = this.first
    }
    this.last = docs[docs.length - 1]

    const items = docs.map((doc) => doc.data())

    if (this.populate) {
      this.items = await this.populate(items)
    } else {
      this.items = items
    }

    this.refetching = false
    return true
  }

  destroyed() {
    if (this.unsubscriber) {
      this.unsubscriber()
    }
  }
}
