








import {
  Component,
  Emit,
  Model,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator'
import { FarmConverter } from '@/models/converters/FarmConverter'
import { Farm } from '@/models/Farm'
import firebase from 'firebase/app'

@Component({
  components: {}
})
export default class FarmSwitcher extends Vue {
  @Prop()
  @Model('change')
  value: Farm

  farms: Farm[] = []

  async created() {
    this.farms = (
      await firebase
        .firestore()
        .collection('farms')
        .where('listed', '==', true)
        .where('has_shop', '==', true)
        .withConverter(new FarmConverter())
        .get()
    ).docs.map((doc) => doc.data())

    if (!this.value) {
      this.updateValue(this.farms[0])
    }
  }

  get selectedFarmId() {
    if (this.value) {
      return this.farms.find((p) => p.id === this.value.id).id
    }
    return this.farms[0].id
  }

  set selectedFarmId(value: string) {
    this.updateValue(this.farms.find((p) => p.id === value))
  }

  @Emit('change')
  updateValue(value: Farm) {
    return value
  }
}
