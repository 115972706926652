var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Tile',{attrs:{"title":"Zusatzprodukte"}},[_c('template',{slot:"button"},[_c('Button',{staticClass:"space-x-2 button button--brand",on:{"click":function($event){return _vm.$router.push({
          name: 'admin/farm/items-detail',
          params: { farmId: _vm.farm.id, itemId: 'neu' }
        })}}},[_c('svg',{staticClass:"w-4 h-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z","clip-rule":"evenodd"}})]),_c('span',[_vm._v("Neues Produkt")])])],1),_c('template',{slot:"content"},[_c('div',{staticClass:"space-y-6"},[(_vm.isAdmin)?_c('div',{staticClass:"mb-4 space-y-1 sm:mt-0"},[_c('label',{staticClass:"label",attrs:{"for":"farm"}},[_vm._v("Betrieb")]),_c('FarmSwitcher',{staticClass:"w-full sm:w-1/2",on:{"change":function($event){return _vm.farmChanged()}},model:{value:(_vm.farm),callback:function ($$v) {_vm.farm=$$v},expression:"farm"}})],1):_vm._e(),_c('div',[(_vm.query)?_c('Table',{attrs:{"query":_vm.query,"headers":_vm.headers},scopedSlots:_vm._u([{key:"name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"flex items-center space-x-3",class:{ 'opacity-25 ': !item.active }},[(item.imageUrl)?_c('div',{staticClass:"flex-shrink-0 w-10 h-10"},[_c('img',{staticClass:"object-cover w-10 h-10 rounded-full",attrs:{"src":item.imageUrl,"alt":item.name}})]):_vm._e(),_c('div',{staticClass:"flex flex-col space-y-1"},[_c('div',{staticClass:"text-sm font-medium text-gray-700"},[_vm._v(_vm._s(item.name))]),_c('div',[_c('span',{staticClass:"flex-shrink-0 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-brand-100 text-brand-800"},[_vm._v(_vm._s(_vm._f("farmItemType")(item.type)))])])])])]}},{key:"amount",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"text-gray-700",class:{ 'opacity-25 ': !item.active }},[_vm._v(_vm._s(_vm._f("currency")(item.amount)))])]}},{key:"action",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"flex justify-end"},[_c('Button',{staticClass:"button button--default",on:{"click":function($event){return _vm.$router.push({
                    name: 'admin/farm/items-detail',
                    params: { farmId: _vm.farm.id, itemId: item.id }
                  })}}},[_vm._v("Bearbeiten")])],1)]}}],null,false,62680965)}):_vm._e()],1)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }