import { Model } from '@/models/Model'
import { FarmItemType } from '@/models/Types'
import { formatMoney } from '@/services/NumberService'

class FarmItem extends Model {
  name: string
  description: string
  amount: number
  get formattedAmount(): string {
    return formatMoney(this.amount)
  }

  type: FarmItemType
  imageUrl: string
  availableWeekdays: number[]
  active: boolean

  constructor(reference?: firebase.firestore.DocumentReference) {
    super(reference)
    this.name = ''
    this.active = true
    this.description = ''
    this.amount = 0
    this.type = 'extra'
    this.imageUrl = ''
    this.availableWeekdays = []
  }
}

export { FarmItem }